<template>
  <div class="store">
    <!-- <div class="store_bg">
      <van-search
        v-model="search"
        shape="round"
        placeholder="请输入名称、货号、规格、品牌搜索"
        :clearable="false"
      >
        <template #left-icon>
          <svg-icon icon-class="home_nav_search_icon" />
        </template>
        <template #left>
          <svg-icon
            icon-class="navbar_dcs_back"
            class="back"
            style="margin-right: 10px"
            @click="toBack"
          />
        </template>
      </van-search>
    </div> -->
    <div class="brand">
      <div class="brand_bg">
        <img :src="obj.picPosition" alt="" />
      </div>
      <div class="brand_info">
        <div class="info_top">
          <img v-lazy="obj.logo" alt="" style="object-fit: contain" />
          <span>{{ obj.brandName }}</span>
        </div>
        <div class="introduce">
          <p>品牌中文名：{{ obj.brandName }}</p>
          <p>品牌外文名：{{ obj.brandEnName }}</p>
          <p>品牌原产地：{{ obj.sourceArea }}</p>
          <p>
            产品特点：<span>{{ obj.brandTrait }}</span>
          </p>
          <p>创始时间：{{ obj.initialTime }}</p>
          <p>
            主要产业：<span>{{ obj.majorIndustry }}</span>
          </p>
          <p>
            品牌官网：<a :href="obj.officialUrl">{{ obj.officialUrl }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="comm">
      <h2>品牌商品</h2>
      <p>
        共<span>{{ total }}</span
        >个商品
      </p>
    </div>
    <div class="sort">
      <p @click="switchover(0)" :class="index === 0 ? 'pitch_on' : ''">
        综合排序
      </p>
      <p @click="switchover(1)" :class="index === 1 ? 'pitch_on' : ''">
        销量
        <span>
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 1 && order ? 'pitch_on' : ''"
          />
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 1 && !order ? 'pitch_on' : ''"
          />
        </span>
      </p>
      <p @click="switchover(2)" :class="index === 2 ? 'pitch_on' : ''">
        价格
        <span>
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 2 && order ? 'pitch_on' : ''"
          />
          <svg-icon
            icon-class="home_more_icon1"
            :class="index === 2 && !order ? 'pitch_on' : ''"
          />
        </span>
      </p>
    </div>
    <div class="goods_list" id="goods_list">
      <goods
        v-for="(item, index) in goods"
        :key="index"
        :title="item.commodityName"
        :goodsInfo="item"
      />
    </div>
    <van-loading size="24px" vertical v-show="loading" style="margin: 40px 0"
      >加载中...</van-loading
    >
    <functionButton :homePageBtn="true" />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import { formatDay } from "@/utils/validate";
import { WechatSharing } from "@/utils/sdk";
import { mapState } from "vuex";

export default {
  name: "brand-detail",
  components: { Goods },
  computed: {
    ...mapState("user", ["tzInfo", "isLogin", "userInfo"]),
  },
  data() {
    return {
      search: "",
      obj: {},
      index: 0,
      total: null,
      order: true, //desc asc 升降序
      goods: [],
      pageNum: 1,
      pages: 1,
      loading: true,
    };
  },

  mounted() {},
  created() {
    if (this.$route.query.id) {
      // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
      sessionStorage.setItem("userUuid", this.$route.query.id);
      // }
    } else {
      this.$router.replace({
        path: "brandDetail",
        query: {
          brandId: this.$route.query.brandId,
          IId: this.$route.query.IId,
          id: sessionStorage.getItem("userUuid")
            ? sessionStorage.getItem("userUuid")
            : "",
        },
      });
    }
    this.getInfo();
    this.getList();
  },
  methods: {
    async getInfo() {
      const data = await this.$API.brand.BrandDetails({
        object: {
          id: this.$route.query.IId,
        },
      });
      if (data.data.code != "SUCCESS") return false;
      this.obj = data.data.data;
      this.obj.initialTime = formatDay(this.obj.initialTime);
      let shareInfo = {
        title: this.obj.brandName,
        desc: this.obj.advertisementDescribe,
        imgUrl: this.obj.logo,
        link: window.location.hash,
      };
      WechatSharing(shareInfo);
    },
    // 获取商品列表
    async getList() {
      this.goods = [];
      this.loading = true;
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: this.$route.query.brandId,
          ceCategoryId: null,
          location: "",
          order: this.order ? "desc" : "asc",
          sortColumn: this.sortColumn,
          memberId: this.isLogin ? this.userInfo.id : undefined,
          userId: this.tzInfo ? this.tzInfo.id : "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (data.data.code != "SUCCESS") return false;
      this.loading = false;
      this.total = data.data.total;

      data.data.data.forEach((item) => {
        item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
          ? item.filePath.split(",")[1]
          : item.filePath.split(",")[0];
        if (
          item.orderCartListItems.length &&
          !item.orderCartListItems[0].isDelete
        ) {
          item.quantity = item.orderCartListItems[0].quantity;
        } else {
          item.quantity = 0;
        }
      });

      this.goods = data.data.data;
      // this.obj.storeHomePageItems.forEach((item) => {
      //   item.enterpriseId = this.obj.enterpriseId;
      //   item.filePath = item.filePath.split(",")[0];
      // });
      // this.goods = this.obj.storeHomePageItems;
      this.$nextTick(function () {
        this.waterFall();
      });
    },
    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1:
          this.order = !this.order;
          break;
        case 2:
          this.order = !this.order;
          this.sortColumn = "retailPrice";
          break;
        default:
          break;
      }
      if (this.index === 1) return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getList();
      }, 500);
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");
      if (!item.length) {
        if (container) container.style.height = "auto";
        return;
      }
      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;

      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left = container.offsetWidth - width * columnCount; //宽度间隔
      let height = 12; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store {
  position: absolute;
  padding-top: 46px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  background: #f5f5f5;
}
.store_bg {
  width: 100%;
}
.brand_bg {
  margin: 16px;
  img {
    width: 100%;
    border-radius: 6px;
  }
}
.brand_info {
  margin: 16px;
  background: #fff;
  border-radius: 6px;
  padding: 14px;
  color: #333;
  .info_top {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 14px;
    }
  }
  .introduce a {
    word-break: break-word;
  }
  p {
    line-height: 30px;
    color: #666;
  }
  .item {
    width: 35%;
    display: flex;
    span {
      width: 30%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
  }
}
.comm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 6px 16px;
  color: #333;
  h2 {
    font-size: 16px;
  }
  span {
    padding: 0 2px;
    color: red;
  }
}
.sort {
  display: flex;
  padding: 12px 0 12px 16px;
  background: #fff;
  p {
    display: flex;
    color: #666666;
    margin-right: 46px;
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 4px;
      .svg-icon {
        font-size: 7px;
        font-weight: bold;
        color: #666666;
      }
      .svg-icon:nth-child(1) {
        transform: rotate(-90deg);
      }
      .svg-icon:nth-child(2) {
        transform: rotate(90deg);
      }
      .pitch_on {
        color: #009a4d;
      }
    }
  }
  .pitch_on {
    color: #009a4d;
  }
}
.goods_list {
  margin: 15px;
  position: relative;
  background: #f5f5f5;
}
::v-deep .van-search {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 100;
  font-size: 14px;
  .van-search__content {
    height: 30px;
    background-color: #f2f2f2;
    .van-cell {
      padding: 0;
      align-items: center;
      .van-field__left-icon {
        margin-right: 8px;
        .svg-icon {
          color: #333333;
        }
      }
      .van-field__control {
        font-size: 12px;
      }
      .van-field__control::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #999999;
      }
      .van-field__control:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999999;
      }
      .van-field__control::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999999;
      }
      .van-field__control:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #999999;
      }
    }
  }
}
/deep/ svg.back {
  color: #333;
}
/deep/.van-search {
  position: relative;
}
</style>
